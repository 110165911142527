import { getUploadSignedUrl } from "@/store/actions"
import FetchFileService from "./FetchFileService"
import { fileConverter } from "./fileConverter"
import { cdn_url } from "./path"

export const complementaryUpload = async (
  dispatch: any,
  files: any,
  story: any,
  isVideo: boolean = false,
  isImage: boolean = false
) => {
  return new Promise(async (resolve, reject) => {
    const data: any = [];
    let timestamp = Date.now(); // Initialize the base timestamp

    for (const file of files) {

      // If the file name already contains "stories/", skip the upload
      if (file?.name?.includes('stories/') || file?.name?.includes('videos/'))  {
        console.log(`Skipping upload for existing file: ${file.name}`);
        data.push(file.name);

        // Resolve the promise when all files are processed
        if (data.length === files.length) {
          resolve(data);
        }
        continue;
      }

      const originalName = file?.name?.split('/')?.pop()?.replace(/\.[^.]+$/, '');
      const extension = file?.name?.match(/\.[^.]+$/)?.[0] || '';

      // Construct the file name with the timestamp
      const fileName = isVideo
        ? `videos/${timestamp}_${originalName}${extension}`
        : isImage
        ? `stories/${story?.title}/memories/${timestamp}_${originalName}.webp`
        : `stories/${story?.title}/memories/${timestamp}_${originalName}${extension}`;


      dispatch(
        getUploadSignedUrl(
          { file: fileName, type: isImage ? 'image/webp' : file?.type },
          async (res: any) => {
            let response: any = null;

            try {
              if (isImage) {
                const newFile = await fileConverter(file);
                response = await FetchFileService(res?.value?.url?.uploadUrl, 'PUT', newFile, 'image/webp');
              } else {
                response = await FetchFileService(res?.value?.url?.uploadUrl, 'PUT', file, file?.type);
              }

              if (response?.ok) {
                data.push(fileName);
              }

              if (data.length === files.length) {
                resolve(data);
              }
            } catch (error) {
              console.error('Error uploading file:', error);
              reject(error);
            }
          }
        )
      );
      timestamp += 1000; // Increment by 1000ms (1 second)
    }
  });
};





export const complementaryDownload = async (dispatch: any, files: any) => {
  return new Promise(async (resolve, reject) => {
    const data: any = []
    for (const file of files) {
      const response = await FetchFileService(`${cdn_url}${file?.video || file}`, 'GET', null);
      const blob = await response?.data?.blob();
      const fileDownloaded = new File([blob], file, { type: blob.type });
      data.push(fileDownloaded)
      if (data.length == files.length) resolve(data)
    }
  })
}